<template>
  <div class="card table-responsive-sm">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Pronóstico</th>
          <!-- <th scope="col">Eliminar</th> -->
        </tr>
      </thead>
      <tbody v-for="d of lstPronostico" v-bind:key="d.id">
        <tr style="cursor: pointer">
          <td @click="irTo(d)">{{ d.nombre }}</td>
          <td>
            <button class="btnEliminarTable" @click="eliminarPronostico(d)">
              <i style="color: #fff" class="far fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject, onMounted } from "vue-demi";
import usePronostico from "../composable/usePronostico";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {},
  setup() {
    const btn = inject("btn");
    const store = useStore();
    const router = useRouter();
    const { getPronostico, lstPronostico, deletePronostico } = usePronostico();
    const objCompetidores = inject("objCompetidores")
    const agregarPronostico = () => {
      btn.value = true;
    };

    const openModal = () => {
      store.commit("pronostico/setCompetidores", null);
      objCompetidores.id = null;
      objCompetidores.deporte_id = null;
      objCompetidores.nombre = "";
      objCompetidores.imagen = "";
    };

    const eliminarPronostico = async (d) => {
      store.commit("showLoader");
      await deletePronostico(d);
      await getPronostico();
      store.commit("hideLoader");
    };

    const irTo = (e) => {
      router.push("/pronosticos-editar/" + e.id);
    };

    onMounted(async () => {
      store.commit("showLoader");
      await getPronostico();
      store.commit("hideLoader");
    });

    return {
      agregarPronostico,
      eliminarPronostico,
      lstPronostico,
      irTo,
      openModal
    };
  },
};
</script>

<style>
</style>