<template>
  <button type="button" class="myButtonEliminar mr-2" @click="openModal()">
    <i class="fas fa-running"></i>
  </button>

  <modalGenerico :open="modal" @close="modal = !modal" :ancho="'1200px'">
    <competidores />
  </modalGenerico>
</template>

<script>
import { inject, ref } from "vue-demi";
// import { useStore } from "vuex";
import modalGenerico from "../../../components/modal/modalGenerico.vue";
import Competidores from "../view/Competidores.vue";
export default {
  components: { modalGenerico, Competidores },
  setup() {
    // const store = useStore();
    const objCompetidores = inject("objCompetidores");

    const modal = ref(false);

    const openModal = () => {
      // store.commit("pronostico/setCompetidores", null);
      // objCompetidores.id = null;
      // objCompetidores.deporte_id = null;
      // objCompetidores.nombre = "";
      // objCompetidores.imagen = "";
      modal.value = true;
      console.log("Hola",objCompetidores)
    };

    return {
      openModal,
      modal,
    };
  },
};
</script>

<style>
</style>