<template>
  <!-- <h3 class="text-light mt-3">Nuevo Pronóstico</h3> -->
  <form @submit.prevent="submitPronostico" class="pronosticoForm">
    <div class="text-end text-light mb-3"><Cerrar /></div>

    <div class="row">
      <div class="col-5">
        <label class="text-light">Titulo</label>
        <input
          type="text"
          class="form-control"
          v-model="objPronostico.pronostico_nombre"
        />
      </div>
      <div class="col-4">
        <label class="text-light">Deporte</label>
        <select
          name=""
          id=""
          class="form-select"
          v-model="deporte_id"
          @change="selectDeporte"
          :disabled="disableDeporte"
        >
          <option :value="d.id" v-for="d in lstDeporte" :key="d.id">
            {{ d.nombre }}
          </option>
        </select>
      </div>
      <div class="col-3">
        <label class="text-light">Imagen</label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="objPronostico.imagen1"
            disabled
          />
          <button class="btn btn-danger" type="button" @click="limpiarInput()">
            X
          </button>
          <button class="btn btn-success" type="button" @click="pegarImagen">
            <i class="fas fa-paste"></i>
          </button>
          <GaleriaBtn />
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-3">
        <button class="myButtonAceptar" type="button" @click="addEvento()">
          Agregar Evento
        </button>
      </div>
      <div class="col-9 text-end">
        <ButtonCompetidores @click="onCompetidores" />
      </div>
    </div>
    <hr style="color: #fff" />
    <!-- <S3Galeria /> -->
    <div class="row mt-3" v-for="e in eventoArr" v-bind:key="e.index">
      <div class="col-3">
        <label class="text-light">Evento</label>
        <input type="text" class="form-control" v-model="e.nombre" />
      </div>
      <div class="col-3">
        <label class="text-light">Fecha inicio</label>
        <input
          type="datetime-local"
          class="form-control"
          v-model="e.fecha_ini"
        />
      </div>
      <div class="col-3">
        <label class="text-light">Fecha fin</label>
        <input
          type="datetime-local"
          class="form-control"
          v-model="e.fecha_fin"
        />
      </div>
      <div class="col-2">
        <label for="" class="text-light">Competidores</label>
        <div>
          <Multiselect
            mode="tags"
            :close-on-select="false"
            :searchable="true"
            :create-option="true"
            v-model="e.competidores"
            :options="arrCompetidores"
            :loading="isLoadin"
          />
        </div>
      </div>
      <div class="col-1">
        <button
          style="margin-top: 30px"
          type="button"
          class="btnEliminarTable"
          @click="removeEvento(e)"
        >
          <i class="far fa-trash-alt"></i>
        </button>
      </div>
    </div>

    <div class="row justify-content-center mt-5">
      <div class="col-md-2">
        <button type="submit" class="myButtonAceptar">Guardar</button>
      </div>
    </div>
  </form>
</template>

<script>
import { inject, onMounted, ref } from "vue-demi";
import Cerrar from "../../../components/button/Cerrar.vue";
import Multiselect from "@vueform/multiselect";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import usePronostico from "../composable/usePronostico";
import ButtonCompetidores from "./ButtonCompetidores.vue";
import GaleriaBtn from "../../../components/button/GaleriaBtn.vue";
import useUtil from "../../../use/useUtil";

export default {
  components: {
    Cerrar,
    Multiselect,
    ButtonCompetidores,
    GaleriaBtn,
  },
  setup() {
    const store = useStore();
    const btn = inject("btn");
    const deporte_id = ref(null);
    const arrCompetidores = ref([]);
    const isLoadin = ref(false);
    const disableDeporte = ref(false);
    const { onPegarTexto } = useUtil();
    const {
      createEvento,
      getPronostico,
      getDeporte,
      lstDeporte,
      getCompetidores,
      lstCompetidores,
    } = usePronostico();
    const eventoArr = ref([
      {
        id: null,
        index: 0,
        nombre: "",
        fecha_ini: null,
        fecha_fin: null,
        competidores: null,
        imagen: null,
        resultado: null,
      },
    ]);

    const limpiarInput = () => {
      objPronostico.imagen1 = null;
    };

    onMounted(async () => {
      store.commit("showLoader");
      await getDeporte();
      store.commit("hideLoader");
    });

    const pegarImagen = async () => {
      objPronostico.imagen1 = "";
      objPronostico.imagen1 = await onPegarTexto();
    };

    const selectDeporte = async () => {
      store.commit("showLoader");
      isLoadin.value = true;
      arrCompetidores.value = [];
      await getCompetidores({ deporte_id: deporte_id.value, nombre: "" });

      await lstCompetidores.value.forEach((element) => {
        let obj = {
          value: element.id,
          label: element.nombre,
        };
        arrCompetidores.value.push(obj);
      });

      isLoadin.value = false;

      store.commit("hideLoader");
    };

    const objPronostico = inject("objPronostico");

    const submitPronostico = async () => {
      store.commit("showLoader");

      let resp = "";
      objPronostico.evento = eventoArr.value;

      resp = await createEvento(objPronostico);

      if (resp.code == 200) {
        Swal.fire("Excelente!", resp.message, "success");
        await getPronostico();
        btn.value = false;
      } else {
        Swal.fire("Error!", resp.message, "error");
      }
      store.commit("hideLoader");
    };

    const addEvento = () => {
      const data = eventoArr.value[eventoArr.value.length - 1];
      const { nombre, fecha_ini, fecha_fin, competidores } = data;

      if (
        nombre.length > 0 &&
        fecha_ini &&
        fecha_fin &&
        competidores !== null
      ) {
        let obj = {
          id: null,
          index: eventoArr.value.length,
          nombre: "",
          fecha_ini: "",
          fecha_fin: "",
          competidores: null,
          imagen: null,
          resultado: null,
        };
        eventoArr.value.push(obj);
        disableDeporte.value = true;
      }
    };

    const removeEvento = (e) => {
      const foundIndex = eventoArr.value.findIndex(
        (element) => element.index == e.index
      );
      if (eventoArr.value.length > 1) {
        eventoArr.value.splice(foundIndex, 1);
      }
    };
    const onCompetidores = () => {
      deporte_id.value = null;
    };

    return {
      submitPronostico,
      eventoArr,
      addEvento,
      removeEvento,
      objPronostico,
      lstDeporte,
      deporte_id,
      selectDeporte,
      arrCompetidores,
      limpiarInput,
      isLoadin,
      disableDeporte,
      pegarImagen,
      onCompetidores,
    };
  },
};
</script>

<style scoped>
.pronosticoForm {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
  width: 100%;
  margin: auto;
}
.img-promociones {
  width: 300px;
}
</style>