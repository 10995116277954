<template>
  <div class="container-fluid">
    <h3 class="text-light mt-3" v-if="!btn">Pronósticos</h3>
    <h3 class="text-light mt-3" v-if="btn">Nuevo Pronóstico</h3>

    <div class="text-end mt-2 mb-2" v-show="!btn">
      <ButtonCompetidores />
      <button class="myButtonAceptar" @click="agregarPronostico">
        Agregar
      </button>
    </div>

    <PronosticoTables v-if="!btn" />
    <PronosticoForm v-else />

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Competidores</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="cerrarModal()"
            ></button>
          </div>
          <div class="modal-body">
            <Competidores />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, onBeforeUnmount, provide, reactive } from "vue-demi";
import PronosticoTables from "../components/PronosticoTables.vue";
import PronosticoForm from "../components/PronosticoForm.vue";
import Competidores from "./Competidores.vue";
import { useStore } from "vuex";
import ButtonCompetidores from "../components/ButtonCompetidores.vue";

export default {
  components: {
    PronosticoTables,
    PronosticoForm,
    Competidores,
    ButtonCompetidores,
  },
  setup() {
    const btn = inject("btn");
    const store = useStore();
    const objPronostico = reactive({
      id: null,
      pronostico_nombre: "",
      deleted: false,
      evento: {},
      imagen1: "",
    });

    const agregarPronostico = () => {
      objPronostico.id = null;
      objPronostico.pronostico_nombre = "";
      objPronostico.deleted = false;
      objPronostico.evento = {};
      objPronostico.imagen1 = "";
      btn.value = true;
    };

    const objCompetidores = reactive({
      id: null,
      deporte_id: null,
      nombre: "",
      imagen: "",
    });

    onBeforeUnmount(() => {
      btn.value = false;
    });

    const cerrarModal = () => {
      // objCompetidores.id = null;
      // objCompetidores.deporte_id = null;
      // objCompetidores.nombre = "";
      // objCompetidores.imagen = "";
    };

    const openModal = () => {
      store.commit("pronostico/setCompetidores", null);
      objCompetidores.id = null;
      objCompetidores.deporte_id = null;
      objCompetidores.nombre = "";
      objCompetidores.imagen = "";
    };

    provide("objPronostico", objPronostico);
    provide("objCompetidores", objCompetidores);

    return {
      btn,
      cerrarModal,
      openModal,
      agregarPronostico,
    };
  },
};
</script>

<style>
</style>