import { computed } from "vue";
import { useStore } from "vuex";

const usePronostico = () => {
  const store = useStore();

  const createEvento = async (obj) => {
    const resp = await store.dispatch("pronostico/createEvento", obj);
    return resp;
  };

  const createCompetidores = async (obj) => {
    const resp = await store.dispatch("pronostico/createCompetidores", obj);
    return resp;
  };

  const getPronostico = async (obj) => {
    const resp = await store.dispatch("pronostico/getPronostico", obj);
    return resp;
  };

  const getCompetidores = async (obj) => {
    const resp = await store.dispatch("pronostico/getCompetidores", obj);
    return resp;
  };

  
  const getEvento = async (obj) => {
    const resp = await store.dispatch("pronostico/getEvento", obj);
    return resp;
  };
  
  const getDeporte = async () => {
    const resp = await store.dispatch("pronostico/getDeporte");
    return resp;
  };
  
  const findEvento = async (obj) => {
    const resp = await store.dispatch("pronostico/findEvento", obj);
    return resp;
  };
  
  const deletePronostico = async (obj) => {
    const resp = await store.dispatch("pronostico/deletePronostico", obj);
    return resp;
  };

  const deleteCompetidores = async (obj) => {
    const resp = await store.dispatch("pronostico/deleteCompetidores", obj);
    return resp;
  };

  const editPronostico = async (obj) => {
    const resp = await store.dispatch("pronostico/editPronostico", obj);
    return resp;
  };

  const editEvento = async (obj) => {
    const resp = await store.dispatch("pronostico/editEvento", obj);
    return resp;
  };

  const editCompetidores = async (obj) => {
    const resp = await store.dispatch("pronostico/editCompetidores", obj);
    return resp;
  };

  const addEvento = async (obj) => {
    const resp = await store.dispatch("pronostico/addEvento", obj);
    return resp;
  };
  
  const deleteEvento = async (obj) => {
    const resp = await store.dispatch("pronostico/deleteEvento", obj);
    return resp;
  };



  return {
    getPronostico, 
    getCompetidores,     
    getEvento,
    getDeporte,
    deletePronostico,   
    deleteCompetidores,  
    createEvento,
    createCompetidores,
    findEvento,
    editPronostico,
    editEvento,
    editCompetidores,
    addEvento,
    deleteEvento,
    lstPronostico: computed(() => store.getters["pronostico/getPronostico"]),  
    lstEvento: computed(() => store.getters["pronostico/getEvento"]),  
    lstDeporte: computed(() => store.getters["pronostico/getDeporte"]),  
    lstCompetidores: computed(() => store.getters["pronostico/getCompetidores"]),  
  };
};

export default usePronostico;
