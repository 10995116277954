<template>
  <div class="container">
    <div class="row">
      <div class="col-4 text-start">
        <label for="">Deporte</label>
        <select
          name=""
          id=""
          class="form-select"
          v-model="objCompetidores.deporte_id"
          @change="selectDeporte()"
        >
          <option :value="d.id" v-for="d in lstDeporte" :key="d.id">
            {{ d.nombre }}
          </option>
        </select>
      </div>

      <div class="col-3 mt-4 text-start">
        <button
          type="button"
          class="myButtonAceptar mt-2"
          @click="nuevoRegistro()"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="row mt-3 justify-content-end">
      <div class="col-3">
        <input
          type="text"
          class="form-control"
          placeholder="Buscar"
          v-model="deporteName"
          @keyup.enter="buscarDeporte"
        />
      </div>
    </div>
    <div
      class="card table-responsive-sm mt-5"
      style="height: 400px; overflow: auto"
    >
      <table class="table table-striped table-hover text-start">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Imagen</th>

            <th scope="col">Eliminar</th>
          </tr>
        </thead>
        <tbody v-for="d of lstCompetidores" v-bind:key="d.id">
          <tr style="cursor: pointer">
            <td @click="selectEdit(d)">
              {{ d.nombre }}
            </td>
            <td @click="selectEdit(d)">
              <img :src="d.imagen" height="50" alt="" />
            </td>
            <td>
              <button class="btnEliminarTable" @click="eliminarCompetidores(d)">
                <i style="color: #fff" class="far fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <ModalGenerico
    :open="isOpenCompetidor"
    @close="isOpenCompetidor = !isOpenCompetidor"
    :ancho="'800px'"
  >
    <h5
      class="modal-title mb-3"
      id="exampleModalLabel"
      v-if="!objCompetidores.id"
    >
      Agregando Competidor
    </h5>
    <h5 class="modal-title mb-3" id="exampleModalLabel" v-else>
      Editando Competidor
    </h5>
    <hr />
    <CompetidoresForm />
  </ModalGenerico>
</template>

<script>
import { inject, onMounted, provide, ref } from "vue-demi";
// import CompetidoresForm from "../components/CompetidoresForm.vue";
import usePronostico from "../composable/usePronostico";
import { useStore } from "vuex";
// import CompetidoresModal from "../components/CompetidoresModal.vue";
import Swal from "sweetalert2";
import ModalGenerico from "../../../components/modal/modalGenerico.vue";
import CompetidoresForm from "../components/CompetidoresForm.vue";

export default {
  components: {
    // CompetidoresModal,
    ModalGenerico,
    CompetidoresForm,
  },
  setup() {
    const {
      getDeporte,
      lstDeporte,
      getCompetidores,
      lstCompetidores,
      deleteCompetidores,
    } = usePronostico();
    const objCompetidores = inject("objCompetidores");

    const isOpenCompetidor = ref(false);
    const deporteName = ref("");

    const store = useStore();

    onMounted(async () => {
      store.commit("showLoader");
      await getDeporte();
      store.commit("hideLoader");
    });

    const selectDeporte = async () => {
      store.commit("showLoader");
      await getCompetidores({
        deporte_id: objCompetidores.deporte_id,
        nombre: deporteName.value,
      });
      store.commit("hideLoader");
    };

    const selectEdit = (d) => {
      objCompetidores.id = d.id;
      objCompetidores.deporte_id = d.deporte_id;
      objCompetidores.nombre = d.nombre;
      objCompetidores.imagen = d.imagen;
      isOpenCompetidor.value = true;
    };

    const nuevoRegistro = () => {
      objCompetidores.id = null;
      // objCompetidores.deporte_id = null;
      objCompetidores.nombre = null;
      objCompetidores.imagen = null;
      if (objCompetidores.deporte_id > 0) {
        isOpenCompetidor.value = true;
      } else {
        Swal.fire({
          icon: "info",
          title: "Debes seleccionar un deporte",
        });
      }
    };

    const eliminarCompetidores = (e) => {
      Swal.fire({
        title: "¿Seguro que desea eliminar este registro?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          store.commit("showLoader");
          objCompetidores.id = e.id;
          await deleteCompetidores(objCompetidores);
          await getCompetidores({
            deporte_id: objCompetidores.deporte_id,
            nombre: "",
          });
          store.commit("hideLoader");
        }
      });
    };

    const buscarDeporte = async () => {
      store.commit("showLoader");
      await getCompetidores({
        deporte_id: objCompetidores.deporte_id,
        nombre: deporteName.value,
      });
      store.commit("hideLoader");
    };

    provide("isOpenCompetidor", isOpenCompetidor);

    return {
      getDeporte,
      lstDeporte,
      objCompetidores,
      selectDeporte,
      lstCompetidores,
      selectEdit,
      nuevoRegistro,
      eliminarCompetidores,
      isOpenCompetidor,
      deporteName,
      buscarDeporte,
    };
  },
};
</script>

<style>
</style>