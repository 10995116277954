<template>
  <div class="row">
    <div class="col-4">
      <label for="">Nombre</label>
      <input
        type="text"
        class="form-control"
        v-model="objCompetidores.nombre"
      />
    </div>
    <div class="col-4">
      <label for="">Imagen</label>

      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          v-model="objCompetidores.imagen"
        />
        <button class="btn btn-danger" type="button" @click="limpiarInput()">
          X
        </button>
        <button class="btn btn-success" type="button" @click="pegarImagen">
          <i class="fas fa-paste"></i>
        </button>
        <GaleriaBtn />
      </div>
    </div>
  </div>

  <div class="row justify-content-center mt-5">
    <div class="col-md-2">
      <button
        type="button"
        class="myButtonAceptar"
        @click="submitCompetidores()"
      >
        <span v-if="!objCompetidores.id">Guardar</span>
        <span v-else>Guardar</span>
      </button>
    </div>
  </div>
</template>

<script>
import { inject, onBeforeUnmount, onMounted } from "vue-demi";
import usePronostico from "../composable/usePronostico";
import { useStore } from "vuex";
import Swal from "sweetalert2";

import GaleriaBtn from "../../../components/button/GaleriaBtn.vue";
import useUtil from "../../../use/useUtil";
export default {
  components: {
    GaleriaBtn,
  },
  setup() {
    const {
      getDeporte,
      lstDeporte,
      createCompetidores,
      getCompetidores,
      lstCompetidores,
      editCompetidores,
      deleteCompetidores,
    } = usePronostico();
    const store = useStore();
    const objCompetidores = inject("objCompetidores");
    const btn = inject("btn");
    const { onPegarTexto } = useUtil();
    const isOpenCompetidor = inject("isOpenCompetidor");
    onMounted(async () => {
      store.commit("showLoader");
      await getDeporte();
      store.commit("hideLoader");
    });

    const submitCompetidores = async () => {
      store.commit("showLoader");
      let resp = "";
      if (objCompetidores.id) {
        resp = await editCompetidores(objCompetidores);
      } else {
        resp = await createCompetidores(objCompetidores);
      }
      await getCompetidores({
        deporte_id: objCompetidores.deporte_id,
        nombre: "",
      });

      if (resp.code == 200) {
        Swal.fire("Excelente!", resp.message, "success");
        isOpenCompetidor.value = false;
      }
      if (resp.code == 400) {
        Swal.fire({
          icon: "info",
          iconColor: "red",
          title: resp.message,
        });
      }
      if (resp.code == 500) {
        Swal.fire("Error!", resp.message, "error");
      }

      objCompetidores.id = null;
      // objCompetidores.deporte_id = null;
      objCompetidores.nombre = null;
      objCompetidores.imagen = null;

      store.commit("hideLoader");
    };

    const selectDeporte = async () => {
      store.commit("showLoader");
      await getCompetidores({
        deporte_id: objCompetidores.deporte_id,
        nombre: "",
      });
      store.commit("hideLoader");
    };

    const limpiarInput = () => {
      objCompetidores.imagen = null;
    };

    const eliminarCompetidores = (e) => {
      Swal.fire({
        title: "¿Seguro que desea eliminar este registro?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          store.commit("showLoader");
          objCompetidores.id = e.id;
          await deleteCompetidores(objCompetidores);
          await getCompetidores({
            deporte_id: objCompetidores.deporte_id,
            nombre: "",
          });
          store.commit("hideLoader");
        }
      });
    };

    const selectEdit = (d) => {
      objCompetidores.id = d.id;
      objCompetidores.deporte_id = d.deporte_id;
      objCompetidores.nombre = d.nombre;
      objCompetidores.imagen = d.imagen;
    };

    onBeforeUnmount(() => {
      objCompetidores.id = null;
      objCompetidores.deporte_id = null;
      objCompetidores.nombre = null;
      objCompetidores.imagen = null;
      btn.value = false;
    });

    const pegarImagen = async () => {
      objCompetidores.imagen = "";
      objCompetidores.imagen = await onPegarTexto();
    };

    return {
      lstDeporte,
      submitCompetidores,
      objCompetidores,
      limpiarInput,
      selectDeporte,
      eliminarCompetidores,
      lstCompetidores,
      selectEdit,
      pegarImagen,
    };
  },
};
</script>

<style>
</style>